<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col class="main-col-margin">
        <v-card class="mx-auto">
          <v-card-title
            class="main-card-title-offset"
          >
            <v-icon large left color="white"> mdi-format-list-bulleted </v-icon>
            <span class="title">
              {{ $t('product.list.title') }}
            </span>
            <v-spacer></v-spacer>

            <v-btn icon @click="createProduct()">
              <v-icon color="white">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="products"
            :sort-by="[]"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-1"
            :loading="isLoading"
            :loading-text="$t('product.list.loading')"
          >
            <template v-slot:[`item.rule`]="props">
              {{ $t(`product.list.label.rule.mappings.${props.item.rule}`) }}
            </template>
            <template v-slot:[`item.type`]="props">
              {{ $t(`product.list.label.type.mappings.${props.item.type}`) }}
            </template>
            <template v-slot:[`item.group`]="props">
              {{ $t(`product.list.label.group.mappings.${props.item.group}`) }}
            </template>
            <template v-slot:[`item.controls`]="props">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="warning"
                @click="editProduct(props.item)"
              >
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="error"
                @click="openDeleteProductDialog(props.item)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{ $t('product.list.delete.title') }}</v-card-title>
          <v-card-text></v-card-text>
          <v-card-actions class="justify-center">
            <v-row class="text-center" style="margin:0px">
              <v-col >
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  text
                  @click="deleteProduct()"
                >
                  <v-icon dark>mdi-delete</v-icon>
                  {{ $t('product.list.delete.controlls.delete') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  text
                  @click="closeDeleteProductDialog()"
                >
                  <v-icon dark>mdi-close-circle</v-icon>
                  {{ $t('product.list.delete.controlls.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
export default {
  name: "ProductList",
  data() {
    return {
      isLoading: true,
      headers: [
        { text: this.$t('product.list.label.name'), align: "start", value: "name" },
        { text: this.$t('product.list.label.description'), value: "description" },
        { text: this.$t('product.list.label.rule.label'), value: "rule" },
        { text: this.$t('product.list.label.type.label'), value: "type" },
        { text: this.$t('product.list.label.group.label'), value: "group" },
        {
          text: this.$t('product.list.label.actions'),
          value: "controls",
          sortable: false,
          align: "center",
        },
      ],
      products: [],
      dialog: false,
      deletingProduct: null,
      error: null,
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    productConstants() {
      if(this.applicationData) {
        return this.applicationData.product.constants; 
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch('loadApplicationData')
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      this.$axios({
        method: "get",
        url: this.$globals.api.product.getAll,
      })
        .then((response) => {
          this.products = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    createProduct() {
      this.$router.push({ name: "product/add" });
    },
    editProduct(product) {
      this.$router.push({ name: "product/edit", params: { id: product.id } });
    },
    openDeleteProductDialog(productData) {
      this.deletingProduct = productData
      this.dialog = true
    },
    closeDeleteProductDialog() {
      this.deletingProduct = null
      this.dialog = false;
    },
    deleteProduct() {
      this.$axios({
        method: "post",
        url: this.$globals.api.product.delete + this.deletingProduct.id,
      })
        .then(() => {
          this.deletingProduct = null
          this.dialog = false
          this.isLoading = true
          this.fetchProducts()
        })
        .catch((error) => {
          this.error = error.response;
        });
    }, 
  },
};
</script>